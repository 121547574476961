import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>What BellMe Expects from our Drivers</h1>
                                <p>Be a good Samaritan (be a good citizen) and assist others who are in need.
                                    Always follow instructions. It’s all about the details.
                                    Always be on time or call the client with an exact ETA from Google Maps. Never
                                    underestimate your ETA! (Future: the app will show your location)
                                    Always be on time and in a clean vehicle, especially the interior.
                                    If a client feels unsafe in your vehicle at any time, because of personal attitude,
                                    dress, personal hygiene, illegal drug odor, harassment, or driving behavior, you
                                    will be deleted from our platform immediately.
                                    Always obey the Rules of the Road; Share the Road, be Considerate, and Drive the
                                    Speed Limit. Pax are grading your driving skills.
                                    Experience is the best teacher. Learn from you mistakes and don’t repeat the same
                                    mistake.
                                    Always have a clean car, especially the interior and all windows, with no clutter on
                                    dash or front seats, and a clean trunk for luggage. There should be no oil stains,
                                    dirty upholstery, rugs or floor mats, or holes in any fabric. One of these
                                    complaints, will delete you from the platform.
                                    Always keep a fresh smelling vehicle with no odors or fragrances. If you smell
                                    illegal drugs, do not allow the client in your vehicle. Some clients have allergies,
                                    and some clients are against ANY drug use. A complaint will get you deleted from the
                                    platform.
                                    Always be attentive, friendly. Smile when greeting. A hand shake at the end of the
                                    ride is a nice touch. Say,” I hope to see you again, or be of service again.”
                                    Clients will tell you they had a nice ride or a great conversation. Some of our
                                    clients will hand-tip you extra if they really like you. You make the difference and
                                    make BellMe look good all the time. Be your best.
                                    Always be courteous and respectful to clients. It is their vehicle when in route.
                                    They are paying you and expect a nice ride.
                                    Always be a courteous driver. Let people in, in front of you. Yield! You’re all
                                    going in the same direction.
                                    Always leave plenty of space between you and the vehicle in front so traffic can
                                    merge on and off ramps and streets.
                                    Always make the client feel welcome and safe and they want to use you and
                                    BellMe again. It’s all about repeat business!
                                    Always give your pax a smooth and worry-free ride. Some clients get car sick.
                                    Cleaning up is at your expense.
                                    Always assist the elderly and handicap with their seat belts.
                                    Always assist the elderly or pax with packages in and out of the trunk, and with
                                    opening and closing the door.
                                    Always allow faster vehicles pass you, move over, don’t hog the road or slow down
                                    traffic, slower traffic stays to the right.
                                    The safest speed limit is the speed of traffic. Keep one car length for every 10
                                    miles per hour speed. More distance for rain or snow.
                                    Always have fresh tires on your vehicle. You will greatly decrease aquaplaning at
                                    higher speeds.
                                    Always get know your client. Some like to talk, some like a cool cabin, some like
                                    their personal music. Keep it professional. Never “hit” on a client. It’s safer if
                                    they “hit” on you but always be careful. You don’t know their true motives. It takes
                                    time to get to know someone. Even when you think you do, you don’t. People can be
                                    fickle (changeable in a heartbeat).
                                    Never give your personal business card to our clients, even if they ask. If we hear
                                    that you did, you’ll be deleted from the network. Honesty is always the best policy.
                                    Once you lie, you will never be trusted again. There is no tolerance with
                                    BellMe.
                                    Never argue with a client, refer them back to BellMe. It’s our job to smooth
                                    any ruffled feathers. Just always agree, apologize, swallow your pride, hide your
                                    ego, and leave the rest to us. We never want to lose a good client or a good driver.
                                </p>
                                <h1>Why drive with BellMe?</h1>
                                <p>We are one of the oldest livery networks in the USA started in 2001, one week before
                                    9-11. Our website is designed to put buyer and seller together and match great
                                    clients with great drivers. In just a few clicks of basic information, our Quotebot
                                    will quote all-inclusive rates for any vehicle type, for any number of pax and at
                                    the lowest available cost. No other website does what our does!
                                    Joe, our founder, is a driver just like you, with over 30 years of experience and
                                    over 1,000,000 customer miles driven. He knows what drivers want; great clients,
                                    repeat business and rates you set. We’re a global free-market.
                                    We’re driver centric. We want all our drivers to earn a living wage or supplement
                                    their income as a Gig Driver. It’s not about being cheap. As a driver with a nicer,
                                    newer, larger vehicle, you have value. Lyft and Uber don’t think so. You are above
                                    average, and our clients are willing to spend a little more, for better service,
                                    with vetted (FOID card) drivers and nicer, larger vehicles.
                                    Taxi and Gig drivers need to learn how to drive and treat clients like a limo
                                    driver, who are the pros in the industry. It’s about being good, all the time. Read
                                    the book, “Raving Fans” and you’ll learn what customer service is all about. It just
                                    takes a little effort to be your best and better than another driver. You make the
                                    difference!
                                    We’ve eliminated the Taxi Meter. Everyone enters minimum rates, mileage rates,
                                    hourly rates, and rates for extra amenities.
                                    Advertise your vehicle or fleet on our website, and it’s our job to get eyes to our
                                    website who are seeking quality ground transportation from operators like you. We’re
                                    going after corporate accounts, travel agents, convention and meeting planner and
                                    individual travelers. Anyone who travels.
                                    Be competitive and sell your best assets. Upload great pictures of you and your
                                    ride. Think of us as eBay Motors. Why do you like one car or truck over the other,
                                    even if more expensive? Check how auto dealers and individual people sell their
                                    vehicles on www.ebaycom. Click on Motors, under Find a Vehicle, click the Find
                                    Vehicle link. Which are the nicer pix? Do the same when you take your exterior and
                                    interior pix. We show sample pix to assist you when you get that far in registering.
                                    Sell yourself, your ride, and your customer service skills with us! We want you to
                                    succeed. It’s a win, win.
                                    We are also customer centric. We want our clients to remain repeat customers
                                    preferring to use you and us, over Lyft, Uber and other TNCs. We are not a TNC but
                                    an aggregator or middleman. Joe also knows what customers expect and demand from a
                                    good driver; on-time service, a clean vehicle, safe driving with a smooth ride, one
                                    who know the route without question, an intelligent conversationalist, a good sense
                                    of humor, who dresses neatly, is groomed, and assists with luggage or any other
                                    request the client expresses in route. Say yes, a lot.
                                </p>
                                <h1>Proper pickup and Drop Off Procedures</h1>
                                <p>This is not brain surgery. Contact the client or BellMe if there is ever a
                                    question for clarity or an issue with anything!
                                    Leave in plenty of time to get to your pickup. You should NEVER be late.
                                    Use Google Maps on our booking screen or confirmation detail. Do not get lost to or
                                    from any location. This and not being able to find a waiting client are the two
                                    biggest client complaints.
                                    Always contact your client when on location unless the client sees and acknowledges
                                    your presence.
                                    Always pick up in front of the address or pickup location. Do not drop or off or
                                    wait across the street. Be thoughtful.
                                    Never make an illegal U-turn to pick up or when there is a client in route. This is
                                    a dangerous maneuver.
                                    Wait for a client. Waiting time applies.
                                    Never leave a pickup location unless directed by the client or BellMe.
                                    When picking up or dropping off on a busy street, direct your client to meet or drop
                                    off the around the corner so you do not block traffic and make it safer for them to
                                    get in or out of the vehicle.
                                    It’s all about communications!
                                    When the client is in the car, notify us digitally that you are in route.
                                    [If there is an accident, make sure everyone is OK. Dial 9-1-1 immediately. Find
                                    witnesses! This is critical to your defense. Take pictures of the scene and each
                                    vehicle. The more pictures the better from every angle and specific damages. These
                                    procedures will help you in court. Being involved in an accident, even a minor
                                    fender bender, is a big deal and a hassle. You want to avoid an accident at all
                                    costs. If it is someone else’s fault, there is nothing you can do but defend
                                    yourself. BE A SAFE (DEFENSIVE) DRIVER AT ALL TIMES. The roads are a racetrack, and
                                    someone always wants to driver faster or inconsiderately. AVOID THESE DRIVERS AND
                                    LET THEM PASS. Drive another day and be happy.]
                                </p>
                                <h1>Airport or Address Pick Up</h1>
                                <ol>
                                    <li>Always read the confirmation carefully for any booking and follow
                                        instructions. It’s all about paying attention to detail. Follow airport
                                        regulations.
                                    </li>
                                    <li>Send a text or call the client per instructions to let them know you are on
                                        location.
                                    </li>
                                    <li>“Hi, this (name) your driver at (airport name). Please contact me when you get
                                        this text for further pickup instructions. What color clothing are you wearing?
                                        I’m driving a make, model, color vehicle with license plate (#).”
                                    </li>
                                    <li>Airport pickup - Once they respond, let them know where you will meet and how
                                        long it will take to arrive.
                                    </li>
                                    <li>Pull up or wait curbside, smile and wave recognition. Open trunk automatically,
                                        if able. Meet the client and introduce yourself again by name. Make them feel
                                        welcome. Open door for client, put luggage in trunk carefully and make sure
                                        trunk is closed. Before you pull away, greet client again. Give ETA to drop off
                                        location.
                                    </li>
                                </ol>
                                <h1>Servicing a Handicap Client - How to handle a client with disabilities?</h1>
                                <p>When I first started driving in Chicago over 30 years, I had to take a one-day
                                    chauffeurs’ class along with taxi drivers who had a three-day course. It is beyond
                                    me why Gig drivers with Lyft and Uber do not have any mandatory training. The
                                    consumer suffers. But most consumers only care about two things. Convenience and
                                    Cheap Prices. Most consumers will make sacrifices to only save a few dollars and not
                                    consider their total satisfaction or safety. These are not the consumers we want as
                                    clients.
                                    The last class and teacher walked in and the first thing he said was, “Think of
                                    yourself as temporarily abled.” What does that mean. It means, that at some point in
                                    your short life, you may or will become disabled either by accident, illness or old
                                    age.
                                    So. remember, I warned you. Just like this teacher warned me. It will happen to you!
                                    The purpose of this very important class is: How do you treat handicap clients?
                                    Some may be blind or in a wheelchair. Deaf people will sign. We will do our best to
                                    match drivers who also sign.
                                    These travelers need more attention and your respect. If a client is blind, speak
                                    normally, not loudly. Some may use a digital typing voice assistant. Some may use a
                                    service dog. Those in a wheelchair can be manual or motorized. Some chairs tear down
                                    easily in three or so parts and need to be put in the trunk. Always assist with the
                                    tear down and rebuild at the end of the ride. They will let you know the best way to
                                    dismantle or reassemble. Designers do a good job to keep it simple.
                                    We will do our best to match drivers with the proper vehicle. Some people travel
                                    with a service dog and are not handicapped with mobility.
                                    We designed our website to assist these travelers with all the different types of
                                    vehicles and amenities that match them to our best drivers who can handle these
                                    travelers with ease.
                                    When caring for a blind person, you always need to be verbal with them. Guide them
                                    with your voice. YOU ARE THEIR EYES!
                                    Call them when on location, texting works sometimes because some carry a voice
                                    assist phone. Introduce yourself at the front door, curbside or at the airport. Let
                                    them know your every action. “I’m going to take your arm and put it my forearm and
                                    I’ll walk you to the vehicle.” As you walk them, let them now if there are any steps
                                    up or down, curbs, uneven pavement, a street to cross, etc.
                                    Open the door. Move their hand from your arm to the edge of car door. Let them get
                                    their bearings and assist them into their seat. Speak to them as to what you are
                                    always doing. Put your hand on one shoulder or the other to guide them in. Watch
                                    their head near the roof. Assist them with their seat belt. Make sure their clothing
                                    is inside and they are secure before closing the door.
                                    Let them know how long it will take to drop off and talk to them as a regular
                                    client.
                                    Do just the opposite when assisting them out of the vehicle. Warn them of curbs,
                                    steps, doors, traffic lights, etc. Ask if they need any other assistance and bid
                                    them a great day. Try not to mention anything with “Seeing” when you say goodbye.
                                    Just be polite.
                                    [This section is like Wikipedia. Add any helpful hints for other drivers to be their
                                    best and keep our clients happy repeat customers]
                                </p>
                                <h1>Recommended Best Vehicles and Used Car Buying Tips
                                </h1>
                                <p>You don’t need to drive a new car to earn a living. But you do need to own a car that
                                    looks and operates “like new”. I believe many of the strict regulations that
                                    disqualify older vehicles from operating as a commercial vehicle prohibits drivers
                                    from earning more money. I believe that many high-quality vehicles built in the late
                                    1990’s should still be allowed to transport people safely. The caveat is that all
                                    commercial vehicles should be safety inspected. New vehicles less than 5 years old
                                    should be inspected once per year. Vehicles over 5 years old inspected every 6
                                    months. The owner must keep detailed repair records, the interior cannot have any
                                    major wear or stains on the seats, dashboard, doors, or headliner. The windows may
                                    not have any cracks or other damage. The exterior body must not have any rust, the
                                    paint must be in like new condition. The body may have no more than 4 small dents
                                    and bumpers must be in good condition without any major dents or paint peeling. The
                                    vehicle should be pulled off the road when a client complains of its overall
                                    condition, no matter which year.

                                    When buying a used or new car, I would suggest knowing which vehicle is going to
                                    earn you the most money and then research your choices with Consumer Reports to find
                                    out which vehicles are the most reliable and less costly to maintain. CR will direct
                                    will allow you to easily compare make, model, year and price range or which year, a
                                    make and model was best built. Many make, and models were not equally built each
                                    year in production. Some years were better built than others. CR allows you to
                                    search easily. My recommendation is to own a mid to large size SUV, crossover,
                                    sport-utility or minivan that holds 4 or more luggage. Airport, long distance,
                                    shared rides and larger group transfers is where you’ll earn more money.

                                    I purchased a 5-year-old 1997 Mercedes S Class with 37,000 miles for about
                                    $30,000.00. I found a few issues with it during my test drive, checking switches and
                                    functions. I went to the dealer parts store and found the cost of parts and labor. I
                                    asked the salesman to repair all and they agreed for the same price I was quoted. It
                                    would have cost me about $5,000.00 more if I didn’t ask. The Mercedes S-Class is
                                    expensive, but the quality and ride is superior than all of the other large luxury
                                    cars. The only problem I have in owning a newer S-Class is that the trunk is
                                    smaller.

                                    It’s always better to buy a newer used vehicle with less miles, than an older one
                                    with more miles and less money. The older the vehicle and more miles, the more parts
                                    to replace and repairs you are going to have to make the first year or so of
                                    ownership. Expect to replace any moving part or body work that is dented or rusted.
                                    You will eventually have to replace the same parts at some point. It’s just a matter
                                    of when. Your vehicle will be on the road more the first few years with a newer
                                    vehicle. Every day in the shop, is one less day of revenue.

                                    Never buy a vehicle with any major rust on body or undercarriage!!! The rust will
                                    only get worse over time and it will be expensive to replace panels or cut out the
                                    rusted areas and weld new metal in its place. That is why you spend a few thousand
                                    more upfront to make sure the vehicle is rust-free.
                                    Expect to many of the repairs and preventive maintenance with your initial purchase
                                    and first year of ownership. Naturally, it all depends which parts were replaced, or
                                    other services performed before your purchase. Get a list of what has been done.
                                    Extended Warranties do work depending on cost and coverage. The extra money you
                                    would spend here, can be saved if you buy the right vehicle and use that same money
                                    in future maintenance.
                                </p>
                                <p>Firstly, never buy a vehicle with airbag suspension! These bags WILL fail and are
                                    very expensive to replace.</p>
                                <p>You can negotiate the price of the car or repairs that are needed before you buy.
                                    Always get it in writing!!!</p>
                                <ol>
                                    <li>
                                        Find a trustworthy mechanic. Ideally, they charge $65 -$85 per hour. Dealerships
                                        are currently charging $100-$150 per hour. Learn to do minor repairs yourself.
                                        Refer to YouTube videos.
                                    </li>
                                    <li>Find a vehicle you feel comfortable driving. Seats and your vision of view are
                                        critical. Handling, power, braking and options are essential.
                                    </li>
                                    <li>These options are nice to have; automatic braking, rear camera, side warning
                                        system, and cruise control. Most in-vehicle LCD touch screens are complex, and
                                        navigation system are useless. They should be an option, but an expensive add-on
                                        you have no choice to pay for. You do get it at a discount when buying used.
                                    </li>
                                    <li>Check for rust; under the doors, trunk, hood, hinges, rocker panels, under
                                        carriage, and engine compartment.
                                    </li>
                                    <li>Ask for repair receipts. It is better if there are any from the previous owner
                                        or some cars have a maintenance booklet the dealer fills out with every
                                        recommended service.
                                    </li>
                                    <li>Start the engine and listen for noises or any smoke from the tail pipe.
                                    </li>
                                    <li>Let the engine. Check all the dashboard lights that are supposed come on and
                                        turn off after starting. Listen for starting noises and how the engine idles,
                                        smoothly. Let the engine get hot.
                                    </li>
                                    <li>Check the engine for any leaks, everywhere. Hoses and clamps. Walk away if you
                                        see any major leaks. Even minor seepage can cost you later.
                                    </li>
                                    <li>Test driver for at least 30 min on streets and highway speeds.</li>
                                    <li>Does it drive straight with your hands off the wheel? Does it stop straight on
                                        easy and hard stops? Alignment, caliper or wheel issues if it does not.
                                    </li>
                                    <li>Does the transmission shift smoothly in all gears, reverse?
                                    </li>
                                    <li>From a stop, accelerate. Can you screech the tires with power? Any power lags or
                                        missing or strange noise or gowning?
                                    </li>
                                    <li>Stop hard. Does it stop straight? Does the transmission continue to shift
                                        smoothly?
                                    </li>
                                    <li>Any smoke from the exhaust.</li>
                                    <li> Do all the lights work outside and inside in the cabin? Do all the switches and
                                        windows work? Do all the doors, hood and trunk open and close properly? Look for
                                        rust. Pull the trunk carpet up, look at the spare tire for any uneven wear or
                                        does it look new. It’s a good sing if never used. Do you see rust under the tire
                                        or in the corners and hard to see areas? Be a detective. This is an investment.
                                        16. Test drive in bad weather; rain and snow. Check for water leaks in the cabin
                                        and around windows and trunk area. See how it handles under extreme conditions.
                                        Check how the cabin air defrosts the windows and warms the cabin. Check the A/C.
                                        Does it blow cold air in winter and especially summer? Get the A/C pressure
                                        tested for any leaks.
                                    </li>
                                    <li>Change or have the dealership change all the fluids; brake, transmission, engine
                                        oil, differential, antifreeze and freon. If they won’t, you will have to do this
                                        soon after your purchase and figure about $300 or more.
                                    </li>
                                    <li> These are the parts that you may have to replace in the first year or depending
                                        on how many miles are on the odometer and hope the mileage is accurate. Check
                                        all these components before you buy. Ask to have the vehicle lifted to see the
                                        undercarriage. Look for body work, dents, or repairs, welding work. Check for
                                        rust, suspension and any leaks around the brakes, engine, transmission,
                                        differential, gas tank and all lines.
                                    </li>
                                    <li>You can easily spend $3000 - $4000 or more just on the basics; fluid changes,
                                        brakes, engine sensors, shocks, springs, belts, light bulbs, wipers, tie rod,
                                        ball joint and gaskets. You can spend an additional $1200 just on minor body
                                        work; both fenders, minor body dings, paint chips, and surface rust. Do not
                                        disregard you Engine Warning Light, Temp Gauge, SRT, etc. You can damage your
                                        engine. Most check engine lights are a simple sensor replacement but can cost
                                        $300-$400 for just one.
                                    </li>
                                    <ul>
                                        <li>Battery</li>
                                        <li>All fluids
                                        </li>
                                        <li>Lightbulbs</li>
                                        <li>Alternator</li>
                                        <li>Starter
                                        </li>
                                        <li> Power steering pump
                                        </li>
                                        <li> Water pump
                                        </li>
                                        <li>Timing Chain – replace around 100,000 to 130,000 miles
                                        </li>
                                        <li> Engine gaskets, hoses, sensors
                                        </li>
                                        <li> Transmission sensors
                                        </li>
                                        <li>A/C compressor or condenser
                                        </li>
                                        <li> Radiator, fan, or thermostat
                                        </li>
                                        <li>Wipers</li>
                                        <li>Shocks, springs and torsion bars
                                        </li>
                                        <li>Tie rods, bushings and ball joints
                                        </li>
                                        <li>Calipers, rotors, brake pads or hoses
                                        </li>
                                        <li>Fuel pump, gas lines, tank float
                                        </li>
                                        <li> Radio
                                        </li>
                                        <li>Vacuum lines
                                        </li>
                                        <li>Fuel evaporator system</li>
                                        <li>Evaporator core
                                        </li>
                                        <li>Air filters
                                        </li>
                                        <li>Tune up, spark plugs, wires, or coils</li>
                                        <li>Engine belts and pulleys</li>
                                        <li>Catalytic converter – there are 4, two front and two rear.
                                        </li>
                                        <li>Muffler and exhaust pipes</li>
                                    </ul>
                                    <li> After you test driver the vehicle, open the hood and look for any oil leaks or
                                        noises. In summer check the A/C after 30 minutes of driving and make sure it’s
                                        still blowing cold air. If there is a major leak, the freon will escape and blow
                                        warmer air.
                                    </li>
                                </ol>
                                <h1>Maintaining your Vehicle</h1>
                                <ol>
                                    <li>It is not cheap to keep a car roadworthy and safe. That is why you cannot earn a
                                        living wage working for Lyft or Uber. Their rates are too cheap, or you will be
                                        driving 45 hours or more a week to earn $800.00 or about $17 per hour. Your
                                        mechanic earns 4x and more per hour. Do the math.
                                    </li>
                                    <li>Find a trusting mechanic who charge about $65 to $85 per hour. Dealerships are
                                        charging $100-$150 per hour
                                    </li>
                                    <li>Find a good body shop that does great work. I found that if you work with a
                                        minority business, you can save a substantial amount of money.
                                    </li>
                                    <li>Buy OEM (original equipment manufacturer) parts.</li>
                                    <li>Ask your mechanic if you can supply the parts. Auto Zone and others are offering
                                        10% discounts and many parts, at a higher cost, come with a lifetime warranty.
                                        If you plan on keeping the car for many years, this is a good value especially
                                        on parts that can wear quickly; calipers, tie rods, starters and pumps. Find a
                                        good junk / bone yard to buy good used parts from low mileage cars that were
                                        totaled.
                                        6. If you can prove you are a commercial driver, dealerships will give a 20%
                                        discount. Some parts are rebuilt from the factory.
                                    </li>
                                    <li>The more repair work you can do, the more money in your bank account.
                                    </li>
                                    <li>I would highly recommend having either $3000.00 cash in reserve or similar
                                        credit available in case of major unexpected repair pops up like a blown engine,
                                        transmission, rear differential, suspension, A/C issue, or vehicle accident.
                                        This is critical to stay on the road working.
                                    </li>
                                    <li>Have, rent or go to Auto Zone for the proper tools. This is half the battle to
                                        maintaining your vehicle.
                                    </li>
                                    <li>Use YouTube videos to see how the average guy fixes things.</li>
                                    <li>Search by entering what you need replaced for the year, make and model. You
                                        should find exactly or something close
                                    </li>
                                    <li>Some minor repairs can still be a hassle and just pay for it.</li>
                                    <li>Use ceramic brake pads for less brake dust
                                    </li>
                                    <li>Use non-ammonia window cleaner for tinted windows
                                    </li>
                                    <li>Use synthetic engine oil and change every 10,000 miles or regular oil and change
                                        every 5000 miles. Changing more often is wasted money.
                                    </li>
                                    <li>Tires; make sure they are inflated properly and have plenty of tread to avoid
                                        aquaplaning and for better handling under all conditions.
                                    </li>
                                    <li> Use a mild detergent to wash your vehicle. I recommend RainX. Do not use Dawn,
                                        it sill strip the wax. Wax your car once a year.
                                    </li>
                                    <li>Use the recommended octane and detergent gas; Mobile, Shell, Costco, BP or other
                                        in your area. Fill up with high octane once a month or so to keep your engine
                                        clean. High octane burns hotter and can keep you fuel injectors. I have a 1997
                                        Mercedes S Class that had over 587,000 miles and used mostly low octane and
                                        alternated with high, I saved about .50 cents a gallon over 17 years (50 gallons
                                        per week) which is about $1300,00 per year in savings.
                                    </li>
                                </ol>
                                <h1>Recommended Vehicles for Reliability
                                </h1>
                                <p><strong>Honda</strong></p>
                                <p><strong>Toyota</strong></p>
                                <p><strong>Mercedes</strong></p>
                            </div>
                            <br/>

                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
